import React from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PromoBlockSection = ({ section, breakpoints }) => {
  const { colour, heading, content: { items } } = section;
  const blockCount = items.length;

  const blockClasses = classNames(
    'HomeBlock-promo',
    `HomeBlock-col-${blockCount}`,
    `bg-lightest-${colour}`
  );

  const imageSizeString = `
    ${breakpoints.desktop} ${100 / blockCount}vw, \
    ${breakpoints.tablet} ${200 / blockCount}vw, \
    100vw
  `;

  const promoBlock = (block) => {
    const {
      url,
      image,
      button,
      image_alt: imageAlt,
      image_srcset: imageSrcset,
      content: blockContent,
      page
    } = block.content;

    const blockHeading = block.content.heading || page?.title;

    return (
      <div key={block.id} className={blockClasses}>
        <div className="mb1">
          {/* Image */}
          <a href={url}>
            <img
              src={image}
              className="w-100"
              alt={imageAlt}
              sizes={imageSizeString}
              srcSet={imageSrcset}
            />
          </a>

          {/* Heading */}
          {blockHeading && (
            <h3 className="pt1 mb3 w-100">
              {parse(blockHeading)}
            </h3>
          )}

          {/* Copy */}
          {blockContent && parse(blockContent)}
        </div>

        {/* Button */}
        <a href={url} className="Button Button--white Button--wide mt3">
          {button}
        </a>
      </div>
    );
  };

  return (
    <div className="HomeSection">
      {heading && (
        <h2 className="mb4 w-100">
          {parse(heading)}
        </h2>
      )}
      {items.map((item) => (promoBlock(item)))}
    </div>
  );
};

PromoBlockSection.propTypes = {
  section: PropTypes.object.isRequired,
  breakpoints: PropTypes.object.isRequired
};

export default PromoBlockSection;
