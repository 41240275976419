import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classNames from 'classnames';

const MultiColumnSection = ({ section, breakpoints }) => {
  const {
    heading,
    colour,
    column_types: columnTypes,
    content: { columns }
  } = section;
  const hasLargeFeature = columnTypes.includes('3');

  const blockColumnClasses = classNames(
    'HomeMulti-column',
    {
      'HomeMulti-column--block--single': hasLargeFeature,
      'HomeMulti-column--block--double': !hasLargeFeature
    }
  );

  const featuredColumnClasses = classNames(
    'HomeMulti-column',
    {
      'HomeMulti-column--featured--large': hasLargeFeature,
      'HomeMulti-column--featured--small': !hasLargeFeature
    }
  );

  const blockImageSizeString = `
    ${breakpoints.desktop} 25vw, \
    ${breakpoints.tablet} 50vw, \
    100vw
  `;

  const featuredImageSizeString = `
    ${breakpoints.desktop} ${hasLargeFeature ? '75vw' : '50vw'}, \
    100vw
  `;

  const renderFeaturedColumn = (column) => {
    const {
      id,
      content: {
        url,
        image,
        heading: blockHeading,
        image_alt: imageAlt,
        image_srcset: imageSrcset,
        content: blockContent,
        display_date: displayDate,
        label,
        showLabel,
        label_colour: labelColour
      }
    } = column;

    return (
      <div key={id} className={featuredColumnClasses}>
        <div className="HomeMulti-block">
          {/* Block image */}
          <a href={url} className="HomeMulti-block-image">
            <img
              src={image}
              className={hasLargeFeature ? '' : 'js-ie-resize'}
              srcSet={imageSrcset}
              alt={imageAlt}
              sizes={featuredImageSizeString}
            />
          </a>

          {/* Block copy */}
          <div className="HomeMulti-block-copy pt2">
            {blockHeading && (
              <h2 className="mb3 w-100">
                <a href={url}>
                  {parse(blockHeading)}
                </a>
              </h2>
            )}

            {blockContent && (
              <div>
                {parse(blockContent)}
              </div>
            )}

            {(displayDate || (label && showLabel)) && (
              <div className="HomeSection-meta pt3">
                {displayDate && (
                  <time className="HomeSection-time">
                    {displayDate}
                  </time>
                )}
                <div />
                {label && showLabel && (
                  <div className={`HomeSection-label HomeSection-label--${labelColour.toLowerCase()} xsmall`}>
                    {label}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBlock = (block) => {
    const {
      showImage,
      url,
      image,
      heading: blockHeading,
      content: blockContent,
      image_srcset: imageSrcset,
      image_alt: imageAlt,
      display_date: displayDate,
      label,
      showLabel,
      label_colour: labelColour
    } = block.content;

    return (
      <div className="HomeMulti-block HomeMulti-block--1">

        {/* Block image */}
        {showImage && (
          <a href={url} className="HomeMulti-block-image">
            <img
              src={image}
              className="js-ie-resize mb2"
              srcSet={imageSrcset}
              alt={imageAlt}
              sizes={blockImageSizeString}
            />
          </a>
        )}

        {/* Block copy */}
        <div className="HomeMulti-block-copy">
          {blockHeading && (
            <h4 className="mb3 w-100">
              <a href={url}>
                {parse(blockHeading)}
              </a>
            </h4>
          )}

          {blockContent && (
            <div>
              {parse(blockContent)}
            </div>
          )}

          {(displayDate || (label && showLabel)) && (
            <div className="HomeSection-meta pt3">
              {displayDate && (
                <time className="HomeSection-time">
                  {displayDate}
                </time>
              )}
              <div />
              {label && showLabel && (
                <div className={`HomeSection-label HomeSection-label--${labelColour.toLowerCase()} xsmall`}>
                  {label}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBlockColumn = ({ items, id }) => (
    <div key={id} className={blockColumnClasses}>
      {items.map((item) => renderBlock(item))}
    </div>
  );

  return (
    <div className="HomeSection">
      <div className="HomeSections-multiColumns">
        {heading && (
          <h2 className={`mb4 w-100 color-${colour == 'white' ? 'black' : colour}`}>
            {parse(heading)}
          </h2>
        )}
        {columns.map((column, index) => {
          const columnType = columnTypes[index];
          if (columnType === '1') {
            return renderBlockColumn(column);
          }
          return renderFeaturedColumn(column);
        })}
      </div>
    </div>
  );
};

MultiColumnSection.propTypes = {
  section: PropTypes.object.isRequired,
  breakpoints: PropTypes.object.isRequired
};

export default MultiColumnSection;
