import React from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const BenefitCtaBlockSection = ({ section }) => {
  const { colour, heading, content: { items } } = section;

  const sectionClasses = classNames(
    'HomeSections-benefitCta',
    {
      'bg-quaternary': colour === 'white',
      'bg-white': colour !== 'white'
    }
  );

  const blockClasses = classNames(
    'HomeBlock-benefit',
    `bg-lightest-${colour}`
  );

  const ctaBlock = (item) => {
    const {
      label,
      image,
      image_alt: imageAlt,
      image_srcset: imageSrcset
    } = item.content;

    return (
      <div key={item.id} className={blockClasses}>
        {/* We don't need the sizes tag because srcset is retina sizes  */}
        <img
          alt={imageAlt}
          src={image}
          srcSet={imageSrcset}
          vspace="10"
          className="HomeBlock-benefit-icon"
        />
        <div className="fw6">{label}</div>
      </div>
    );
  };

  return (
    <div className="HomeSection">
      <div className={sectionClasses}>
        <div>
          {heading && (
            <h3 className="tc tl-l pt2 pr3-l">
              {parse(heading)}
            </h3>
          )}
          <div className="HomeSections-benefitCta-buttons">
            <a
              href="https://www.consumer.org.nz/articles/become-a-member"
              className="Button Button--white HomeSections-benefitCta-button"
            >
              Learn more
            </a>
            <a
              href="https://www.consumer.org.nz/purchase/join"
              className={`Button Button--${colour === 'white' ? 'red' : colour} HomeSections-benefitCta-button`}
            >
              Join Consumer
            </a>
          </div>
        </div>
        <div className="HomeBlock-benefits">
          {items.map((item) => (ctaBlock(item)))}
        </div>
      </div>
    </div>
  );
};

BenefitCtaBlockSection.propTypes = {
  section: PropTypes.object.isRequired
};

export default BenefitCtaBlockSection;
