import React from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const MagazineSection = ({ section, breakpoints }) => {
  const { content, colour, heading } = section;
  const {
    url,
    image,
    button,
    image_alt: imageAlt,
    image_srcset: imageSrcset,
    content: magazineContent
  } = content;

  const imageSizeString = `
    ${breakpoints.desktop} 20vw, \
    ${breakpoints.tablet} 33vw, \
    100vw
  `;

  const blockClasses = classNames(
    'HomeBlock-magazine',
    `bg-lightest-${colour}`
  );

  return (
    <div className="HomeSection">
      <div className={blockClasses}>
        {/* Image */}
        <a href={url}>
          <img
            className="HomeBlock-magazine-image"
            alt={imageAlt}
            src={image}
            srcSet={imageSrcset}
            sizes={imageSizeString}
          />
        </a>
        {/* Copy */}
        <div className="HomeBlock-magazine-copy">
          {heading && <h2 className="mb4">{parse(heading)}</h2>}
          <div className="richtext">
            {magazineContent && parse(magazineContent)}
          </div>
          <a href={url} className={`Button Button--${colour} mt4`}>{button}</a>
        </div>
      </div>
    </div>
  );
};

MagazineSection.propTypes = {
  section: PropTypes.object.isRequired,
  breakpoints: PropTypes.object.isRequired
};

export default MagazineSection;
