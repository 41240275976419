import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Sections
import {
  MagazineSection,
  VideoSection,
  PromoBlockSection,
  PromoSingleSection,
  QuoteBlockSection,
  IconBlockSection,
  BenefitCtaBlockSection,
  MultiColumnSection
} from '@home/components';

const Home = ({ sections, hideCtas, breakpoints }) => {
  useEffect(() => {
    if (document.documentMode) resizeIEImages();
  }, []);

  // many images are using object-fit: cover to allow the different types (and dimensions) of images
  // Fix for IE distorting images
  const resizeIEImages = () => {
    jQuery('#home-content img.js-ie-resize').each(function () {
      const t = jQuery(this);
      const s = `url(${t.attr('src')})`;
      const p = t.parent();
      const d = jQuery('<div class="img"></div>');
      p.append(d);
      d.css({
        height: t.css('height'),
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': '50% 20%',
        'background-image': s
      });
      t.hide();
    });
  };

  // Filter out sections that should be hidden for paying members
  if (hideCtas) {
    sections = sections.filter((section) => section.type !== 'cta');
  }

  const components = {
    magazine: MagazineSection,
    video: VideoSection,
    promo: PromoBlockSection,
    'promo-single': PromoSingleSection,
    quote: QuoteBlockSection,
    'multi-column': MultiColumnSection,
    icon: IconBlockSection,
    cta: BenefitCtaBlockSection,
  };

  // These section types use the section colour as the background
  // Other section types use the section colour as the accent
  // This was determined by design
  const useColourAsBackground = [
    'video', 'icon', 'multi-column'
  ];

  const sectionBgColor = (section) => {
    if (useColourAsBackground.includes(section.type)) {
      return section.colour;
    }

    // Benefits cta background is always white
    if (section.type === 'cta') {
      return 'white';
    }

    // The accent colour is white, so the background needs to be different
    if (section.colour === 'white') {
      return 'grey';
    }

    // Default bg colour
    return 'white';
  };

  return (
    <div className="HomeSections">
      {sections.map((section) => {
        const Component = components[section.type];
        if (!Component) { return <></>; }
        return (
          <div className={`HomeSection-wrapper bg-lightest-${sectionBgColor(section)}`}>
            <Component
              key={section.id}
              section={section}
              breakpoints={breakpoints}
            />
          </div>
        );
      })}
    </div>
  );
};

Home.propTypes = {
  sections: PropTypes.array.isRequired,
  hideCtas: PropTypes.bool.isRequired
};

export default Home;
