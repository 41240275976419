import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classNames from 'classnames';

const IconBlockSection = ({ section }) => {
  const { colour, heading, content: { items } } = section;

  const blockCount = items.length;
  // Colour is the bg colour, so make the block colour different
  const blockClasses = classNames(
    'HomeBlock-icon',
    `HomeBlock-col-${blockCount}`,
    {
      'bg-quaternary': colour === 'white',
      'bg-white': colour !== 'white'
    }
  );

  const iconBlock = (block) => {
    const {
      url,
      image,
      button,
      heading: blockHeading,
      image_alt: imageAlt,
      image_srcset: imageSrcset,
      content: blockContent
    } = block.content;

    return (
      <div key={block.id} className={blockClasses}>
        <div>
          {/* We don't need the sizes tag because srcset is retina sizes  */}
          <img alt={imageAlt} src={image} srcSet={imageSrcset} vspace="10" />
          {/* Heading */}
          {blockHeading && (
            <h3 className="HomeBlock-icon-heading w-100">
              {parse(blockHeading)}
            </h3>
          )}
        </div>

        {/* Copy */}
        {blockContent && parse(blockContent)}

        {/* Button */}
        <a
          href={url}
          className={`HomeBlock-icon-button Button Button--${colour}`}
          target={url.includes('http') ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {button}
        </a>
      </div>
    );
  };

  return (
    <div className="HomeSection">
      {heading && (
        <h2 className={`HomeSections-icon-heading w-100 color-${colour == 'white' ? 'black' : colour}`}>
          {parse(heading)}
        </h2>
      )}
      {items.map((item) => (iconBlock(item)))}
    </div>
  );
};

IconBlockSection.propTypes = {
  section: PropTypes.object.isRequired
};

export default IconBlockSection;
