import React from 'react';
import ReactDOM from 'react-dom';
import { Home } from '@home/components';

const renderHome = (element) => {
  const data = $(element).data('home-data');
  ReactDOM.render(
    <Home {...data} />,
    element
  );
};

$('html').on('consumer.dependencies.loaded', () => {
  const homeElement = document.getElementById('home-content');
  if (homeElement) renderHome(homeElement);
});
