import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classNames from 'classnames';

const QuoteBlockSection = ({ section }) => {
  const { colour, heading, content: { items } } = section;
  const blockCount = items.length;

  const blockClasses = classNames(
    'HomeBlock-quote',
    `HomeBlock-col-${blockCount}`,
    `bg-lightest-${colour}`
  );

  const quoteBlock = (block) => {
    const { quote, name } = block.content;

    return (
      <div key={block.id} className={blockClasses}>
        <blockquote className="HomeBlock-quote-text">
          {quote}
        </blockquote>
        <footer className="HomeBlock-quote-name fw6">
          {name}
        </footer>
      </div>
    );
  };

  return (
    <div className="HomeSection HomeSections-quote">
      {heading && (
        <h2 className={`pb4 w-100 color-${colour == 'white' ? 'black' : colour}`}>
          {parse(heading)}
        </h2>
      )}
      {items.map((item) => (quoteBlock(item)))}
    </div>
  );
};

QuoteBlockSection.propTypes = {
  section: PropTypes.object.isRequired
};

export default QuoteBlockSection;
