/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const VideoSection = ({ section }) => {
  const { heading, content } = section;
  const { videoUrl, date, content: sectionContent } = content;

  return (
    <div className="HomeSection pb3">
      <div className="HomeBlock-col-2">
        <div className="HomeSections-video-wrapper">
          <iframe
            src={videoUrl}
            width="640"
            height="360"
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          />
        </div>
      </div>
      <div className="HomeBlock-col-2">
        <div className="HomeSections-video-copy">
          {heading && <h2 className="mb3 w-100">{parse(heading)}</h2>}

          {sectionContent && (
            <div className="richtext">
              {parse(sectionContent)}
            </div>
          )}

          {content.date && (
            <time className="HomeSection-time">
              {date}
            </time>
          )}
        </div>
      </div>
    </div>
  );
};

VideoSection.propTypes = {
  section: PropTypes.object.isRequired
};

export default VideoSection;
