import React from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PromoSingleSection = ({ section, breakpoints }) => {
  const { colour, heading, content } = section;
  const {
    url,
    button,
    image,
    image_alt: imageAlt,
    image_srcset: imageSrcset,
    content: promoContent,
  } = content;

  const blockClasses = classNames(
    'HomeBlock-promo-single',
    `bg-lightest-${colour}`
  );

  const imageSizeString = `
    ${breakpoints.desktop} 33vw, \
    ${breakpoints.tablet} 50vw, \
    100vw
  `;

  return (
    <div className="HomeSection">
      <div className={blockClasses}>
        {/* Image */}
        <a href={url}>
          <img
            src={image}
            alt={imageAlt}
            srcSet={imageSrcset}
            className="HomeBlock-promo-single-image js-ie-resize"
            sizes={imageSizeString}
          />
        </a>
        {/* Copy */}
        <div className="HomeBlock-promo-single-copy">
          {heading && <h3 className="pb3 mb1">{parse(heading)}</h3>}
          <div className="richtext">
            {promoContent && parse(promoContent)}
          </div>
          <a href={url} className={`Button Button--${colour} mt4`}>{button}</a>
        </div>
      </div>
    </div>
  );
};

PromoSingleSection.propTypes = {
  section: PropTypes.object.isRequired,
  breakpoints: PropTypes.object.isRequired
}

export default PromoSingleSection;
